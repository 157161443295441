<template>
  <div id="app">
    <Navbar class="col-span-12 md:col-span-12 xl:col-span-12" v-if="$store.state.isLogged" />
    <router-view />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
export default {
  components: {
    Navbar
  },
  methods: {
    async start() {
      if (!this.$store.state.user || !this.$store.state.user._id) return;
      const userReq = await this.$http.post("/v1/users/meLZV2");

      if (!userReq.data || !userReq.data.ativo) {
        if (this.store) {
          this.store.commit("logout");
        } else {
          localStorage.removeItem("token");
          localStorage.removeItem("user");
        }

        return this.$router.push({ path: "/login" }).catch((err) => {
          if (err) {
            window.location.replace("/login");
          }
        });
      }

      localStorage.setItem("user", JSON.stringify(userReq.data));
      this.$store.commit("login", userReq.data);
      this.$store.commit("setConfig", { colorPrincipal: userReq.data.colorPrincipal, logo: userReq.data.logo });

      if (userReq.data.operador && userReq.data.notifcacaoNavegador) {
        this.checkSubscriptionOperator();
      }

      if (userReq.data.operador && userReq.data.admin) {
        return this.$router.push({ path: "/" });
      }

      if (userReq.data.operador && userReq.data.visualizarChat) {
        return this.$router.push({ path: "/chats" });
      }

      if (userReq.data.operador && !userReq.data.chatAcesso) {
        return this.$router.push({ path: "/campanhas" });
      }

      if (userReq.data.operador) {
        return this.$router.push({ path: "/chats" });
      }

      this.sockets.subscribe(`logoutToken-${this.$store.state.user._id}`, async function (token) {
        if (localStorage.getItem("token") && localStorage.getItem("token").toString() === token.toString()) {
          await this.$http.post("/v1/operador/sessao/logout", { id: this.$store.state.user._id });
          if (this.store) {
            this.store.commit("logout");
          }
          this.$router.push({ path: "/login" }).catch(() => {});
        }
      });

      return this.$router.push({ path: "/" });
    },

    async checkSubscription(subscription) {
      try {
        const response = await fetch(subscription.endpoint, { method: "HEAD" });

        if (response.status === 404 || response.status === 410) {
          console.warn("❌ Assinatura de push inválida ou expirada.");
          return false;
        }

        return true; // Assinatura ainda válida
      } catch (error) {
        console.error("❌ Erro ao validar assinatura:", error);
        return false;
      }
    },

    urlBase64ToUint8Array(base64String) {
      const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
      const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");
      const rawData = window.atob(base64);
      return new Uint8Array([...rawData].map((char) => char.charCodeAt(0)));
    },

    async checkSubscriptionOperator() {
      const publicVapidKey = process.env.VUE_APP_KEYWEBPUSH;

      if (!("serviceWorker" in navigator)) {
        console.error("❌ Service Worker não suportado!");
        return;
      }

      const permission = await Notification.requestPermission();
      if (permission !== "granted") {
        console.error("❌ Permissão negada pelo usuário.");
        return this.$vToastify.error("Necessário acionar permissão de notificação do navegador");
      }

      // Obtém o Service Worker registrado
      const register = await navigator.serviceWorker.ready;

      // Verifica se já existe uma assinatura ativa
      let existingSubscription = await register.pushManager.getSubscription();

      if (existingSubscription) {
        const isValid = await this.checkSubscription(existingSubscription);

        if (!isValid) {
          await existingSubscription.unsubscribe(); // Remove a assinatura antiga
          existingSubscription = null;
        }
      }

      // Se não houver assinatura válida, cria uma nova
      if (!existingSubscription) {
        const subscription = await register.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: this.urlBase64ToUint8Array(publicVapidKey)
        });

        const subscriptionData = subscription.toJSON();

        await this.$http.put(`/v1/operador`, {
          notifcacaoNavegador: true,
          endpoint: subscriptionData.endpoint,
          keys: {
            auth: subscriptionData.keys.auth,
            p256dh: subscriptionData.keys.p256dh
          }
        });
      }
    }
  },
  async mounted() {
    this.start();
  },
  beforeDestroy() {
    if (!this.$store.state.user || !this.$store.state.user._id) return;
    this.sockets.unsubscribe(`logoutToken-${this.$store.state.user._id}`);
  }
};
</script>

<style scoped>
>>> {
  --vs-controls-color: #6b7280;
  --vs-controls-size: 0.7;
  --vs-actions-padding: 4px 11px 0 3px;
}
</style>
